import React from 'react'
import {useParams} from 'react-router-dom'
import ProjectenData from './ProjectenData'






const projectArray = omschrijving =>{
  return ProjectenData.find(item => {
    return item.omschrijving=== omschrijving;
  })
}

function ProjectenDetail(props) {
  const {omschrijving} = useParams()
   const project = projectArray(omschrijving)
    return (
      <div className="contactContainer projectDetailIntro">
        <h1>Projecten</h1> 
        {/* {console.log(ProjectenData)} */}
        {/* {console.log(omschrijving)} */}
        {console.log(project.afbeeldingen)}
    {/* {console.log(project)} */}
        <p>{project.title}</p>
        <p>{project.intro}</p>
        <div className="projectDetailImageContainer">
          {project.afbeeldingen.map((item, key) => <img src={process.env.PUBLIC_URL + '/images/'+project.omschrijving +'/'+ item} />
        
        )}

        </div>
      </div>
    );
  }
  
  export default ProjectenDetail;
  