import React from 'react'
import arjen1 from './../assets/images/about/over1.jpg'
import arjen2 from './../assets/images/about/over2.jpg'

import {NavLink} from 'react-router-dom'

function AboutArticle() {
    return (
      <section className="articleContainer overContainer">
        <article>
          <h1>Van hobby naar <b>droombaan</b></h1>
          <p>Al sinds 2006 bezig met spuitwerk voor onder andere: old timers, young timers, auto onderdelen, maar ook binnen-deuren en ander meubilair enzovoorts. </p>
          <p>Alles is welkom en geen project is te gek!</p>
          <NavLink to="/projecten">Bekijk werk</NavLink>
        
        </article>
        <div className="articleImages">
          <div style={{backgroundImage: `url(${arjen1})`}}>
          </div>
          <div style={{backgroundImage: `url(${arjen2})`}}>
          </div>
        </div>
    </section>
    );
  }
  
  export default AboutArticle;
  