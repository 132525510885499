import React from 'react'

function ProjectenIntro() {
    return (
      <div className="contactContainer">
        <h1>Projecten</h1>
        <p>De kunst van het lakken</p>
        
      </div>
      
    );
  }
  
  export default ProjectenIntro;
  