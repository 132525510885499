import React from 'react'

function ContactForm() {
    return (
      <div className="contactContainer">
            <h1>Contact</h1>
            <p>Wat kunnen we voor jou betekenen?</p>
            <p>Stuur ons een <a href="mailto:mail@agcolorfultouch.nl">e-mail.</a></p>
      </div>
    );
  }
  
  export default ContactForm;
  