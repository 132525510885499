import React, {useState, useEffect} from 'react'
import AboutArticle from './AboutArticle'
import AboutIntro from './AboutIntro'
import ArticleMoois from './ArticleMoois'
import ArticleMooisSmall from './ArticleMooisSmall'
import ArticleRecensies from './ArticleRecensies'
import ContactForm from './ContactForm'
import Footer from './Footer'
import HomeArticle from './HomeArticle'
import HomeBefore from './HomeBefore'
import HomeIcons from './HomeIcons'
import HomeProject from './HomeProject'
import Menu from './Menu'
import ProjectenIntro from './ProjectenIntro'
import Projecten from './Projecten'
import ProjectenDetail from './ProjectenDetail'
import NotFound from './NotFound'
import headerImage from './../assets/images/homepage-header.jpg'

import './../App.css';
import {Route,  Routes} from 'react-router-dom'
function Home() {
  const [page, setPage] = useState('home')
  const [project,  setProject] = useState(false)
    return (
      <div >
                                      
        <Routes>
           
          <Route  path="/" element={

                                <div className="App" style={{ backgroundImage: `url(${headerImage})`, 
                                backgroundRepeat: "no-repeat", 
                                backgroundSize: "cover" }}>  
                                <div className="homePolygon"></div>
                                    <div className="homeRoute">
                                    
                                  <Menu />
                                  <HomeArticle />
                                    </div> 
                                </div>
                                  }>
        </Route>
           
        </Routes>                              
       

        <Routes>
          <Route path="/" element={ <><HomeIcons />
                                    <ArticleMoois />
                                    <HomeProject project={project} setProject={setProject} />
                                    <ArticleMooisSmall /> </>
        }>

          </Route>
          <Route path="/projecten">
            <Route index 
            
             element={
                                      <>
                                       <Menu />
                                      <ProjectenIntro />
                                      <Projecten />
                                      <ArticleMooisSmall />
                                      </>
                                    }>
                                      </Route>
                                      <Route path=":omschrijving" element={
                                        <>
                                         <Menu />
                                        <ProjectenDetail />
                                        <ArticleMooisSmall />
                                        </>
                                    }>
                                  
          </Route>
            
                                        
          </Route>
          
          <Route path="/over" element={<>
                                      <Menu />
                                      <AboutIntro />
                                      <HomeIcons /> 
                                      <AboutArticle />
                                      <ArticleRecensies />
                                      <ArticleMooisSmall />
                                      </>
                                      }>
          </Route>
          <Route path="/contact" element={
                                      <>
                                       <Menu />
                                      <ContactForm />
                                      </>
                                      }> 
        </Route>
        <Route path="*" element={<NotFound/>}></Route>
        </Routes>
        <Footer />
      </div>
    );
  }
  
  export default Home;
  