import React from 'react'

function AboutIntro() {
    return (
      <div className="contactContainer">
        <h1>Over</h1>
        <p>Welkom bij AG colourful touch</p>
        <p>Spuitwerk nauwkeurig in detail voor elk materiaal en in elke kleur mogelijk.</p>
      </div>
      
    );
  }
  
  export default AboutIntro;
  