import React from 'react'
import agIcon from "./../assets/images/agIcon.png"
import logoAG from './../assets/images/logoAG.png'
import logoJoy from "./../assets/images/seejoy-logo.png"
import logoPB from './../assets/images/logoPB.png'
import {NavLink} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF} from '@fortawesome/free-brands-svg-icons'

function Footer() {
    return (
      <div className="footerContainer">
         <div className="hr">
          <div><img src={agIcon} /></div>
         </div>
         <footer>
            <div className="footerLogo">
              <img src={logoAG} className="footerAGGroot"/>
              <div>
                <h2>Socials</h2>
                <a href="https://www.facebook.com/profile.php?id=100089190494095" target="_blank"> <FontAwesomeIcon icon={faFacebookF} /></a>
                <a href="https://www.instagram.com/agcolourfultouch/" target="_blank">  <FontAwesomeIcon icon={faInstagram} /></a>
              </div>
              
            </div>
            <div className="footerMenu">
              <nav>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/Over">Over</NavLink>
                <NavLink to="/Projecten">Projecten</NavLink>
                <NavLink to="/Contact">Contact</NavLink>
              </nav>
            </div>
            <div className="footerGegevens">
              <h2>Gegevens</h2>
              <a href="mailto:info@agcolourfultouch.nl">info@agcolourfultouch.nl</a>
              <a href="tel:00316 12458438">+31 6 124 584 38</a>
              <p>KVK: 88986098</p>
              <p>BTW: NL004678859B79</p>
            </div>
            <div className="footerBy">
              <h2>Design door</h2>
              <img src={logoJoy} className="logoJoy"/>
              <h2>Techniek door</h2>
              <a href="https://paulienbouw.nl/" target="_blank"><img src={logoPB} className="logoPB"/></a>
              
              </div>
         </footer>
      </div>
    );
  }
  
  export default Footer;
  