import React from 'react'
import Projecten from './Projecten'
import {NavLink, Link} from 'react-router-dom'
import ProjectenData from './ProjectenData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";


import peugeot from './../assets/images/projecten/1/1.jpg'
import subaru from './../assets/images/projecten/2/1.jpg'
import jaguar from './../assets/images/projecten/3/1.jpg'
import aanhanger from './../assets/images/projecten/4/1.jpg'
import bmw from './../assets/images/projecten/5/1.jpg'
import bureau from './../assets/images/projecten/6/2.jpg'
import pontiac from './../assets/images/projecten/7/1.jpg'
import velgen from './../assets/images/projecten/8/1.jpg'

const imageMap = {
  velgen: velgen,
  peugeot: peugeot,
  jaguar: jaguar,
  aanhanger: aanhanger,
  bmw: bmw,
  bureau: bureau,
  pontiac: pontiac,
  subaru: subaru
}
function HomeProject() {
    return (
      <div className="homeProjecten">
        <h1>Projecten</h1>
        <div className="projectContainer">
        {ProjectenData.map( (item) => <li key={item.id}><Link to={'projecten/'+ item.omschrijving}><img src={imageMap[item.omschrijving]} alt="projecten" /> <div className="projectCopy"><p>{item.title} <FontAwesomeIcon icon={faArrowRight} /></p><p>{item.intro}</p></div></Link></li> 
          )}
        
      </div>
        <NavLink to="/projecten" className="homeProjectenButton">Bekijk projecten</NavLink>
      </div>
    );
  }
  
  export default HomeProject;
  