import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import logo from './../logoAG.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function Menu() {
  const [openMenu, setOpenMenu] = useState(false)
    return (
      <div className="topMenu">
        <nav className="desktop">
          
          <NavLink to="/"><img src={logo} className="header-logo" alt="logo" /></NavLink>
          <div>
            <NavLink to="/Over">Over</NavLink>
            <NavLink to="/Projecten">Projecten</NavLink>
          </div>
          <NavLink to="/Contact" className="contact">Contact</NavLink>
          <div className="toggleIcon" >
            <FontAwesomeIcon icon={faBars} />
          </div>
        </nav>
        <nav className="mobile"> 
          
          <NavLink to="/"><img src={logo} className="header-logo" alt="logo" /></NavLink>
          <div className="toggleIcon" onClick={() =>setOpenMenu(!openMenu)}>
            <FontAwesomeIcon icon={openMenu?faTimes:faBars} />
          </div>
          </nav>
          <nav>
          {openMenu &&
          <div className="menuItemsMobile"> 
            <NavLink to="/">Home</NavLink>
            <NavLink to="/Over">Over</NavLink>
            <NavLink to="/Projecten">Projecten</NavLink>
            <NavLink to="/Contact" >Contact</NavLink>
        </div>
        
          }
          
         
        </nav>
      </div>
    );
  }
  
  export default Menu;
  