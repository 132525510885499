import React from 'react'
import fast from './../assets/images/fast.png'
import spray from './../assets/images/spray.png'
import bubbles from './../assets/images/bubbles.png'
import chat from './../assets/images/chat.png'
function HomeIcons() {

const iconData = [
  {id: 1, icon: chat, text: "Meedenken met de klant voor het beste resultaat"},
  {id: 2, icon: spray, text: "Gebruik van professionele apparatuur"},
  {id: 3, icon: bubbles, text: "Hoogwaardige lakken voor de beste kwaliteit"}
  //klokje icoon vervangen voor praatwolkjes!
]

    return (
      <div className="iconContainer" style={{backgroundColor: "white"}}>
        {iconData.map(item => <div key={item.id}>
                                	<img src={item.icon}/>
                                  <p>{item.text}</p>
                              </div>)}
                              
      </div>
    );
  }
  
  export default HomeIcons;
  