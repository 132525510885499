import React from 'react'
import {NavLink} from 'react-router-dom'

function ArticleMooisSmall() {
    return (
      <div className="articleSmallContainer">
        <h1>Uniek project?</h1>
        <h2>Laten we er iets moois van maken!</h2>
        <NavLink to="/Contact">Neem contact op</NavLink>
      </div>
    );
  }
  
  export default ArticleMooisSmall;
  