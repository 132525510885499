import React from 'react'

function HomeBefore() {
    return (
      <div className="App">
        <h1>HomeBefore</h1>
      </div>
    );
  }
  
  export default HomeBefore;
  