import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import peugot from "./../assets/images/homepage1.jpg"
import subaru from './../assets/images/homepage2.jpg'
import {NavLink} from 'react-router-dom'

function HomeArticle() {
    return (
      <section className="articleContainer articleHome">
        <article>
          <h1>Strak in de lak</h1>
          <p>Tot in detail</p>
          <NavLink to="/projecten">Bekijk werk</NavLink>
          <blockquote className="homeRecensie">
            <span><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></span>
            <q>"Velgen bij dit bedrijf gekocht. Volledig gerestaureerd en in kleur laten spuiten voor een aantrekkelijke prijs. Erg tevreden over het resultaat. Goede communicatie en service vanuit bedrijf. Alles in overleg gedaan."</q>
            <p>Sander (JR11)</p>
          </blockquote>

        </article>
        <div className="articleImages">
          <div style={{backgroundImage: `url(${peugot})`}}>
          </div>
          <div style={{backgroundImage: `url(${subaru})`}}>
          </div>
        </div>
    </section>
    );
  }
  
  export default HomeArticle;
  