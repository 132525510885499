import React, {useState} from 'react'
import profile from './../assets/images/profielArjen.jpg'
import logo from './../assets/images/agIcon.png'


function ArticleMoois() {

  const [toggleToepassing, setToggleToepassing] = useState(false)
  const [toggleGarantie, setToggleGarantie] = useState(false)
  const [toggleCommunicatie, setToggleCommunicatie] = useState(false)
  const [toggleLak, setToggleLak] = useState(false)
  const [toggleKleur, setToggleKleur] = useState(false)

  const mountedStyle = { animation: "inAnimation 250ms ease-in" };
const unmountedStyle = {
  animation: "outAnimation 270ms ease-out",
  animationFillMode: "forwards"
};
    return (
      <section className="articleContainer articleMoois">
        <article style={{backgroundImage: `url(${logo})`}}>
          <p>Uniek Project?</p>
          <p>Laten we er iets moois van maken!</p>
          <div className="profileBossMan">
            <div className="profilePicture">
              <img src={profile} alt="profile of Arjen"/>
            </div>
            
            <span>
              <p>"Passie voor het vak al sinds 2008"</p>
              <p>Arjen (Colour Wizard)</p>
            </span>
          </div>
        
        </article>
        <div className="articleNav">
          <li           onClick={() =>setToggleToepassing(!toggleToepassing)}
                        onMouseEnter={() => setToggleToepassing(true)}
                        onMouseLeave={() => setToggleToepassing(false)}        
          >Toepassing</li>
          {toggleToepassing &&
            <ol className="navItems">
              <li>- Hout</li>
              <li>- Metaal</li>
              <li>- Kunststof</li>
              <li>- Polyester</li>
            </ol>
           }
          <li onClick={() =>setToggleGarantie(!toggleGarantie)}
              onMouseEnter={() => setToggleGarantie(true)}
              onMouseLeave={() => setToggleGarantie(false)} 
            >Garantie</li>
          {toggleGarantie &&
            <p>Op mogelijke productiefouten.</p>
          }
          <li onClick={() =>setToggleCommunicatie(!toggleCommunicatie)}
            onMouseEnter={() => setToggleCommunicatie(true)}
              onMouseLeave={() => setToggleCommunicatie(false)} 
          >Communicatie</li>
          {toggleCommunicatie &&
            <p>Het hele proces wordt gefotografeerd en gecommuniceerd met de klant.</p>
          }
          <li onClick={() =>setToggleLak(!toggleLak)}
              onMouseEnter={() => setToggleLak(true)}
              onMouseLeave={() => setToggleLak(false)} 
          >Conventionele lak</li>
          {toggleLak &&
            <ol className="navItems">
              <li>- PU Hout lak</li>
              <li>- Industriële lak</li>
              <li>- Uni lak</li>
              <li>- Netallic lak</li>
            </ol>
          }
          <li 
          onClick={() =>setToggleKleur(!toggleKleur)}
          onMouseEnter={() => setToggleKleur(true)}
          onMouseLeave={() => setToggleKleur(false)} 
          >Elke kleur mogelijk</li>
          {toggleKleur &&
            <p>Elke kleur is mogelijk, van Ral tot aan Kleurenwaaier.</p>

          }
        </div>
    </section>
    );
  }
  
  export default ArticleMoois;
  