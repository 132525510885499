import React from 'react'

const ProjectenData = [
  {
    nummer: 1,
    title: 'Peugeot 206cc',
    intro: 'van Ice Blue naar Red Metallic',
    omschrijving: 'peugeot',
    afbeeldingen: ['1.jpg', '2.jpg', '3.jpg']
  }, 
  {
    nummer: 2,
    title: 'Subaru Impreza WRX',
    intro: 'van 01G naar Custom 01G',
    omschrijving: 'subaru',
    afbeeldingen:['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg']
  }, 
  {
    nummer: 3,
    title: 'Jaguar',
    intro: '',
    omschrijving: 'jaguar',
    afbeeldingen: ['1.jpg', '2.jpg', '3.jpg', '4.jpg']
  }, 
  {
    nummer: 4,
    title: 'Aanhanger',
    intro: '',
    omschrijving: 'aanhanger',
    afbeeldingen: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg']
  }, 
  {
    nummer: 5,
    title: 'BMW',
    intro: '',
    omschrijving: 'bmw',
    afbeeldingen: ['1.jpg', '2.jpg', '3.jpg', '4.jpg','5.jpg']
  }, 
  {
    nummer: 6,
    title: 'Bureaubladen',
    intro: '',
    omschrijving: 'bureau',
    afbeeldingen: ['1.jpg', '2.jpg', '3.jpg']
  }, 
  {
    nummer: 7,
    title: 'Pontiac LeMans `70',
    intro: 'van origineel naar BMW M5 zilver',
    omschrijving: 'pontiac',
    afbeeldingen: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg']
  }, 
  {
    nummer: 8,
    title: 'Velgen',
    intro: '',
    omschrijving: 'velgen',
    afbeeldingen: ['1.jpg', '2.jpg', '3.jpg', '4.jpg']
  }, 






]  
  export default ProjectenData;
  