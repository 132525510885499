import React from 'react'

function Footer() {
    return (
      <div className="App">
         <h1>This page does not excist. Please use menu to navigate to a page.</h1>
      </div>
    );
  }
  
  export default Footer;
  