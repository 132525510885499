import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function ArticleRecensies() {
    return (
      <div className="articleHome recensieContainer">
        <h1>Recensies</h1>
        <div>
           <blockquote className="homeRecensie">
            <span><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStarHalfStroke} /></span>
            <q>"Top resultaat en snel gerealiseerd! Zelfs een foutje van mijn kant wist Arjen snel en vakkundig op te lossen!"</q>
            <p>Stefan (Voerboot Vissen)</p>
          </blockquote>
          <blockquote className="homeRecensie">
            <span><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></span>
            <q>"Velgen bij dit bedrijf gekocht. Volledig gerestaureerd en in kleur laten spuiten voor een aantrekkelijke prijs. Erg tevreden over het resultaat. Goede communicatie en service vanuit bedrijf. Alles in overleg gedaan."</q>
            <p>Sander (JR11)</p>
          </blockquote>
          <blockquote className="homeRecensie">
            <span><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStarHalfStroke} /></span>
            <q>"Er is altijd een streef naar 95% perfectie want 100% bestaat niet. Communicatie verliep goed tijdsplanning wat minder maar het eindresultaat mag er wezen."</q>
            <p>Kevin (Peugeot 206cc)</p>
          </blockquote>
          </div>
      </div>
    );
  }
  
  export default ArticleRecensies;
  